import { Avatar, secondaryColor, useOpenChat } from '@orus.eu/pharaoh'
import { UnstyledButton } from '../../../atoms/unstyled-button'

export function CompactSubscriptionHeaderContactButton(): JSX.Element {
  const openChat = useOpenChat()
  return (
    <UnstyledButton variant="tertiary" onClick={openChat}>
      <Avatar size="40" icon="comment-question-duotone" color={secondaryColor} secondaryColor={secondaryColor} />
    </UnstyledButton>
  )
}
