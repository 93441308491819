import { css } from '@emotion/react'
import { checkDefinedAndNotNull } from '@orus.eu/error'
import { HorizontalLogo, Text, primaryColor, spacing, useOpenChat, useScreenType } from '@orus.eu/pharaoh'
import { UserDropdown } from '@orus.eu/pharaoh/src/components/features/user-dropdown/index'
import { memo } from 'react'
import { trpcReact } from '../../../../client'
import { useDisconnect } from '../../../../lib/hooks/use-disconnect'
import { useNavigateTo } from '../../../../lib/hooks/use-navigate-to-route'
import { useSession } from '../../../../lib/session'
import { usePermissions } from '../../../../lib/use-permissions'
import { CompactSubscriptionHeaderContactButton } from '../../../organisms/subscription-step-layout/header/compact-subscription-header-contact-button'
import { SubscriptionHeaderContactButton } from '../../../organisms/subscription-step-layout/header/subscription-header-contact-button'

export const SubscriptionV2Header = memo<{ title?: string | undefined }>(function SubscriptionV2Header({ title }) {
  const screenType = useScreenType()
  const { user } = useSession()
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        padding: ${screenType === 'desktop' ? spacing[40] : spacing[50]} 0;
        align-items: center;
      `}
    >
      {title ? (
        <Text variant="subtitle2">{title}</Text>
      ) : (
        <HorizontalLogo color={primaryColor} height={screenType === 'desktop' ? 21 : 17} />
      )}
      {user ? (
        <UserDropdownLoader />
      ) : screenType === 'desktop' ? (
        <SubscriptionHeaderContactButton />
      ) : (
        <CompactSubscriptionHeaderContactButton />
      )}
    </div>
  )
})

const UserDropdownLoader = memo(function UserDropdownLoader() {
  const openChat = useOpenChat()
  const disconnect = useDisconnect()
  const navigateToAccountPage = useNavigateTo({ to: '/' })

  const isCustomerApp = usePermissions().type === 'client'
  if (!isCustomerApp) return null

  const customer = trpcReact.users.loadMyInformation.useQuery()
  if (!customer.data) return null

  return (
    <UserDropdown
      firstName={checkDefinedAndNotNull(customer.data.firstName)}
      lastName={checkDefinedAndNotNull(customer.data.lastName)}
      onOpenChat={openChat}
      onOpenAccountPage={navigateToAccountPage}
      onLogout={disconnect}
    />
  )
})
