import { ProgressBar } from '@orus.eu/pharaoh'
import { memo } from 'react'

export const SubscriptionV2ProgressBar = memo<{
  progress: number
  className?: string
}>(function SubscriptionHeaderProgressBar({ progress, className }) {
  // We don't want to start with a progress bar too close to zero to avoid demotivation
  return <ProgressBar initialProgress={20} progress={progress} className={className} />
})
